// import React, { useEffect } from 'react';
// import { BrowserRouter, Routes, Route} from "react-router-dom"
import { ChakraProvider, extendTheme} from "@chakra-ui/react" 
import { Router } from "./Router"
import './css/Padrao.css'; 
import 'animate.css'; 
import { cfg } from "./dados/dados";

const theme = extendTheme({ 
  colors: {
    tema:cfg.theme.cores,
    scroll:{
      bg    : '#000000',
      barra : '#EA6925',
      borda: '3px',
      largura: '6px',
      hover : 'yellow'
    }
  }
});
export const App = () => {
//   useEffect(() => {
//       console.log('reder APP');
//       return ()=>{
//         if(G.conectado){
//           G.desconectarNode();
//           GA.desconectarNode()
//           console.log('reload APP'); 
//         } 
//       }
//   }, []) 
  return (
    <ChakraProvider theme={theme} >
      <Router/>
    </ChakraProvider>
  )
} 
