import { useLocation } from "react-router-dom";  
import { cfg } from "./dados/dados";
import Layout from "./layout/Layout";

export const Theme = () => {
    const location = useLocation();  
    var pag=[];
    (location.pathname.toUpperCase()+'/').replace('//','/').split('/').forEach((path)=>{
        if(path!=''){
            pag.push(path);
        }
    });  
    var lay = {}
    cfg.layout.forEach((l)=>{ 
        if(l.slug.toUpperCase()==pag[0]){
            lay = l;
        }
    }) 
     
    return (
        <> 
            <Layout page={pag} lay={lay}  ></Layout>
        </>
    )
}